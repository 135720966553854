/* eslint-disable jsx-a11y/anchor-is-valid */
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { Segment } from 'semantic-ui-react'
import { GridColumn, Grid, Divider, Dropdown, Button } from 'semantic-ui-react'
import { useEffect, useState } from 'react';
import $ from 'jquery'

function App() {
  const [catalogue, setCatalogue] = useState([])
  const [selectedBrand, setSelectedBrand] = useState('')
  const [selectedModel, setSelectedModel] = useState('')
  const [selectedWidth, setSelectedWidth] = useState('')
  const [selectedHeight, setSelectedHeight] = useState('')

  const [widthSearchValue, setWidthSearchValue] = useState('')
  const [heightSearchValue, setHeightSearchValue] = useState('')

  useEffect(() => {
    window.sendSize()
  }, []);

  useEffect(() => {
    $.getJSON(`https://middleman.mvhr.shop/catalogue.json`)
      .then((response => {
        setCatalogue(response)
      }))
  }, [])

  useEffect(() => {
    setSelectedModel('')
    setSelectedHeight('')
  }, [selectedBrand, selectedWidth]);

  useEffect(() => {
    setSelectedModel('')
    setSelectedBrand('')
  }, [selectedHeight, selectedWidth]);

  const brands = catalogue.map((filter) => filter.brand).sort((a, b) => a.localeCompare(b))
  const brandsValues = [...new Set(brands)].map((brand) => {
    return {
      text: brand,
      value: brand
    }
  })

  const models = catalogue.filter((filter) => filter.brand.toLowerCase() === selectedBrand.toLowerCase()).map((filter) => {
    return {
      text: filter.model,
      value: filter.model
    }
  }).sort((a, b) => a.value.localeCompare(b.value))

  const handleFilterOpen = () => {
    const filterObject = catalogue.find((filter) => filter.model === selectedModel)
    window.open(filterObject.url)
  }


  const availableDimensions = new Set()
  for (const filter of catalogue) {
    const dimensionSplit = filter.dimensions.split('x');
    availableDimensions.add(dimensionSplit[0].trim())
    availableDimensions.add(dimensionSplit[1].trim())
  }

  const dimensionValues = [...availableDimensions].sort((a, b) => a - b).map((dimension) => {
    return {
      text: dimension,
      value: dimension
    }
  })

  const availableHeightDimensions = [...new Set(catalogue.filter((filter => {
    try {
      const dimensionSplit = filter.dimensions.split('x');
      return selectedWidth === dimensionSplit[0].trim() || selectedWidth === dimensionSplit[1].trim()
    } catch (e) {
      return false
    }
  })).map((filter) => {
    const dimensionSplit = filter.dimensions.split('x');
    if (dimensionSplit[0].trim() === selectedWidth) {
      return dimensionSplit[1].trim()
    } else {
      return dimensionSplit[0].trim()
    }
  }))]

  const heightDimensionValues = [...availableHeightDimensions].sort((a, b) => a - b).map((dimension) => {
    return {
      text: dimension,
      value: dimension
    }
  })

  const availableModels = catalogue.filter((filter => filter.dimensions.includes(`${selectedWidth} x ${selectedHeight}`) || filter.dimensions.includes(`${selectedHeight} x ${selectedWidth}`))).map((filter, index) => {
    return {
      text: `${filter.brand} ${filter.model}`,
      value: filter.model
    }
  })

  const handleWidthSearch = (_event, data) => {
    let sanitisedQuery = data.searchQuery.replace(/[^0-9.]+/g, "")
    setWidthSearchValue(`${sanitisedQuery}`)
  }

  const handleHeightSearch = (_event, data) => {
    let sanitisedQuery = data.searchQuery.replace(/[^0-9.]+/g, "")
    setHeightSearchValue(`${sanitisedQuery}`)
  }

  const handleDesignerOpen = () => {
    const dimensions = getDimensions()
    window.open(`https://designer.mvhr.shop/?sku=WF%2C${dimensions[0]}%2C${dimensions[1]}%2CQF`)
  }

  function getDimensions() {
    let width = ''
    let height = ''

    if (selectedWidth !== '') {
      width = selectedWidth
    }

    if (selectedHeight !== '') {
      height = selectedHeight
    }

    if (widthSearchValue !== '') {
      width = widthSearchValue
    }

    if (heightSearchValue !== '') {
      height = heightSearchValue
    }

    return [width, height]
  }

  function validDimensionExist() {
    const dimensions = getDimensions()
    if (dimensions[0] !== '' && dimensions[1] !== '') {
      return true
    }
    return false
  }

  function isCatalogueProduct() {
    const dimensions = getDimensions()
    const models = catalogue.filter((filter => filter.dimensions.includes(`${dimensions[0]} x ${dimensions[1]}`) || filter.dimensions.includes(`${dimensions[1]} x ${dimensions[0]}`)))
    return models.length > 0
  }

  return (
    <Segment>
      <Grid columns={3} stackable centered>
        <GridColumn width={7} style={{ textAlign: 'center' }} id="columnHeight">
          <h3>Find by Manufacturer and Model</h3>
          <Dropdown
            placeholder='Select Manufacturer'
            fluid
            selection
            options={brandsValues}
            onChange={(_event, { value }) => setSelectedBrand(value)}
          />
          <div style={{ marginTop: '1em' }}>
            <Dropdown
              placeholder='Select Model'
              fluid
              selection
              options={models}
              onChange={(_event, { value }) => setSelectedModel(value)}
              disabled={selectedBrand === ''}

            />
          </div>
          <div style={{ marginTop: '1em' }}>
            <Button basic disabled={selectedModel === ''} onClick={handleFilterOpen}>View Filter</Button>
            <Button color='yellow' basic onClick={() => window.openModal('not_listed')}>Filter Missing?</Button>
          </div>
        </GridColumn>
        <GridColumn width={2}>
          <Divider vertical>OR</Divider>
        </GridColumn>
        <GridColumn width={7} style={{ textAlign: 'center' }}>
          <h3>Find by Dimensions (Millimeters)</h3>
          <Dropdown
            placeholder='Select or Type Width'
            fluid
            selection
            onChange={(_event, { value }) => setSelectedWidth(value)}
            options={dimensionValues}
            search
            allowAdditions
            additionLabel='Custom Width: '
            onSearchChange={handleWidthSearch}
            searchQuery={widthSearchValue}
          />
          <div style={{ marginTop: '1em' }}>
            <Dropdown
              placeholder='Select or Type Height'
              fluid
              selection
              onChange={(_event, { value }) => setSelectedHeight(value)}
              options={heightDimensionValues}
              disabled={selectedWidth === ''}
              search
              allowAdditions
              additionLabel='Custom Width: '
              onSearchChange={handleHeightSearch}
              searchQuery={heightSearchValue}
            />
          </div>
          {selectedHeight !== '' && isCatalogueProduct() ?
            <div style={{ marginTop: '1em' }}>
              <Dropdown
                placeholder='Select Model'
                fluid
                selection
                onChange={(_event, { value }) => setSelectedModel(value)}
                options={availableModels}
              />
            </div>
            : null}
          <div style={{ marginTop: '1em' }}>
            {isCatalogueProduct()
              ? <Button basic disabled={selectedModel === ''} onClick={handleFilterOpen}>View Filter</Button>
              : <Button basic disabled={!validDimensionExist()} onClick={handleDesignerOpen}>View in Designer</Button>}
            <Button color='yellow' basic onClick={() => window.openModal('not_listed')}>Filter Missing?</Button>
          </div>
        </GridColumn>
        <GridColumn width={16} style={{ textAlign: 'center' }}>
          <button style={{ marginLeft: '0.4em' }} className="mini ui yellow button compact basic"
            onClick={() => window.openModal('not_listed')}><b>Can't find what you're looking for?</b></button>
          <div className="help-link">
            <a href="#" onClick={() => window.openModal('cf')}>Help</a>
          </div>
        </GridColumn>
      </Grid>
    </Segment>
  );
}

export default App;
